<template>
    <div style='width:850px;'>
        <el-form :model="form" ref="formRef" label-width="180px" v-loading='listLoading' :disabled='isDisabled'>
            <div class='rowClass'>
                <el-form-item label="营业执照图片：" prop="name" :rules="[{required:true,message:'请上传办学许可证图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload1'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess1"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img1" :src="img1" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="办学许可证图片：" prop="contactPerson" :rules="[{required:true,message:'请上传办学许可证图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload2'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess2"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img2" :src="img2" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </div>
            <div class='rowClass'>
                <el-form-item label="前台外观一图片：" prop="contactPerson" :rules="[{required:true,message:'请上传前台外观一图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload3'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess3"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img3" :src="img3" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="前台外观二图片：" prop="contactPerson" :rules="[{required:true,message:'请上传前台外观二图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload4'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess4"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img4" :src="img4" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </div>
            <div class='rowClass'>
                <el-form-item label="理论学习场景一图片：" prop="contactPerson" :rules="[{required:true,message:'请上传理论学习场景一图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload5'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess5"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img5" :src="img5" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="理论学习场景二图片：" prop="contactPerson" :rules="[{required:true,message:'请上传理论学习场景二图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload6'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess6"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img6" :src="img6" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </div>
            <div class='rowClass'>
                <el-form-item label="实操学习一图片：" prop="contactPerson" :rules="[{required:true,message:'请上传实操学习一图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload7'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess7"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img7" :src="img7" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="实操学习二图片：" prop="contactPerson" :rules="[{required:true,message:'请上传实操学习二图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload8'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess8"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img8" :src="img8" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </div>
            <div style='margin-left:43px;'>
                <el-form-item label="学习入口二维码图片：" prop="contactPerson" :rules="[{required:true,message:'请上传学习入口二维码图片',trigger:'blur'}]">
                    <el-upload
                    ref='upload9'
                    class="avatar-uploader"
                    :action="action"
                    :headers='headers'
                    :show-file-list="false"
                    :on-success="handleSuccess9"
                    :on-error='handleError1'
                    :before-upload="beforeAvatarUpload">
                    <img v-if="img9" :src="img9" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </div>
        </el-form>
        <el-form>
            <el-form-item style='text-align:center'>
                <el-button type='primary' @click='prevStep'>上一步</el-button>
                <el-button type='primary' @click='submit' v-if='isDisabled == false'>提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Bus from '../../../util/bus'; // 组件传值的中转站
import Qs from 'qs';
import {uploadTempPicUrl,addAgencyInfo,updateAgencyInfo} from '@/api/api'
export default {
    data(){
        return {
            form:{
                businessLicenseImgUrl:'',
                schoolLicenceImgUrl:'',
                receptionOneImgUrl:'',
                receptionTwoImgUrl:'',
                classRoomOneImgUrl:'',
                classRoomTwoImgUrl:'',
                operationOneImgUrl:'',
                operationTwoImgUrl:'',
                lessonQRCodeImgUrl:''
            },
            action:'',
            img1:'', // 营业执照图片
            img2:'', // 办学许可证图片
            img3:'', // 前台外观一
            img4:'', // 前台外观二
            img5:'', // 理论学习场景一
            img6:'', // 理论学习场景二
            img7:'', // 实操学习一
            img8:'', // 实操学习二
            img9:'', // 学习入口二维码
            listLoading:false,
            isDisabled:false
        }
    },
    props:['data','getInfo'],
    computed:{
        headers(){
            return {
                "Authorization": 'Bearer ' + localStorage.getItem('Token')
            }
        },
    },
    watch:{
        data:function(newVal){
            if(newVal){
                this.listLoading = true
                // 数据回显
                this.form = {
                    businessLicenseImgUrl:newVal.BusinessLicenseImgUrl,
                    schoolLicenceImgUrl:newVal.SchoolLicenceImgUrl,
                    receptionOneImgUrl:newVal.ReceptionOneImgUrl,
                    receptionTwoImgUrl:newVal.ReceptionTwoImgUrl,
                    classRoomOneImgUrl:newVal.ClassRoomOneImgUrl,
                    classRoomTwoImgUrl:newVal.ClassRoomTwoImgUrl,
                    operationOneImgUrl:newVal.OperationOneImgUrl,
                    operationTwoImgUrl:newVal.OperationTwoImgUrl,
                    lessonQRCodeImgUrl:newVal.LessonQRCodeImgUrl,
                }
                this.img1 = newVal.BusinessLicenseImgUrl // 营业执照图片
                this.img2 = newVal.SchoolLicenceImgUrl // 办学许可证图片
                this.img3 = newVal.ReceptionOneImgUrl // 前台外观一
                this.img4 = newVal.ReceptionTwoImgUrl // 前台外观二
                this.img5 = newVal.ClassRoomOneImgUrl // 理论学习场景一
                this.img6 = newVal.ClassRoomTwoImgUrl // 理论学习场景二
                this.img7 = newVal.OperationOneImgUrl // 实操学习一
                this.img8 = newVal.OperationTwoImgUrl // 实操学习二
                this.img9 = newVal.LessonQRCodeImgUrl // 学习入口二维码
                if(newVal.Status == 1){
                    this.isDisabled = true // 审核通过，表单禁用
                }else{
                    this.isDisabled = false
                }   
                setTimeout(() =>{
                    this.listLoading = false
                },600)
            }           
        }
    },
    methods:{
        // 营业执照图片上传
        handleSuccess1(res,file,fileList){
            if(res.Success){
                this.img1 = res.Response.weburl
                this.form.businessLicenseImgUrl = res.Response.webupurl
            }
        },
        handleError1(err,file,fileList){
            this.$message.error('上传失败')
        },
        beforeAvatarUpload(file){
            var size = file.size / 1024 / 1024 < 2
            if(!size){
                return this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            var arr = ['image/png','image/jpg','image/jpeg']
            if(arr.includes(file.type) == false){
                return this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
            } 
        },
        // 办学许可证图片
        handleSuccess2(res,file,fileList){
            if(res.Success){
                this.img2 = res.Response.weburl
                this.form.schoolLicenceImgUrl = res.Response.webupurl
            }
        },
        // 前台外观一
        handleSuccess3(res,file,fileList){
            if(res.Success){
                this.img3 = res.Response.weburl
                this.form.receptionOneImgUrl = res.Response.webupurl
            }
        },
        // 前台外观二
        handleSuccess4(res,file,fileList){
            if(res.Success){
                this.img4 = res.Response.weburl
                this.form.receptionTwoImgUrl = res.Response.webupurl
            }
        },
        // 理论学习场景一
        handleSuccess5(res,file,fileList){
            if(res.Success){
                this.img5 = res.Response.weburl
                this.form.classRoomOneImgUrl = res.Response.webupurl
            }
        },
        // 理论学习场景二
        handleSuccess6(res,file,fileList){
            if(res.Success){
                this.img6 = res.Response.weburl
                this.form.classRoomTwoImgUrl = res.Response.webupurl
            }
        },
        // 实操学习一
        handleSuccess7(res,file,fileList){
            if(res.Success){
                this.img7 = res.Response.weburl
                this.form.operationOneImgUrl = res.Response.webupurl
            }
        },
        // 实操学习二
        handleSuccess8(res,file,fileList){
            if(res.Success){
                this.img8 = res.Response.weburl
                this.form.operationTwoImgUrl = res.Response.webupurl
            }
        },
        // 学习入口二维码
        handleSuccess9(res,file,fileList){
            if(res.Success){
                this.img9 = res.Response.weburl
                this.form.lessonQRCodeImgUrl = res.Response.webupurl
            }
        },

        // 上一步
        prevStep(){
            Bus.$emit('getPrevFormData',this.form) // 将表单数据传给上一页
            this.$emit('toFirstTab',true) // 给父组件传值，去第一个tab
        },
        // 表单提交
        submit(){
            var arr = []
            arr.push(this.img1,this.img2,this.img3,this.img4,this.img5,this.img6,this.img7,this.img8,this.img9)
            if(arr.includes('')){
                var tag = true
            }else{
                var tag = false
            }        
            if(JSON.stringify(this.getInfo) != '{}'){
                // 编辑
                var params = {
                    ...this.form,
                    id:this.getInfo.ID
                }
                updateAgencyInfo(params).then(res => {
                    if(res.data.Success){
                        this.$message.success(res.data.Message)
                    }else{
                        this.$message.error(res.data.Message)
                    }
                }).catch(() => {})
            }else{
                // 新增
                if(tag == false){
                    this.$refs.formRef.clearValidate()
                    var params = {...this.form}
                    addAgencyInfo(params).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() =>{})
                }else{
                    this.$message.warning('请将图片上传完整')
                }               
            }
        },
    },
    created(){
        this.action = uploadTempPicUrl
    },
    mounted(){
        // 得到基本信息中的表单数据
        Bus.$on('getFormValue',value =>{
            this.form = Object.assign(this.form,value) 
        })
    }
}
</script>

<style lang="stylus" scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader-icon{
        border:1px dashed #409EFF;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 158px;
        height: 158px;
        line-height: 158px;
        text-align: center;
    }
    .avatar {
        width: 158px;
        height: 158px;
        display: block;
    }
    .rowClass{
        display:flex;
        justify-content:space-around;
    }
</style>